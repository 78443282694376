import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import dogIllustration from "../images/navigation.svg";

function AboutPage() {
  return (
    <Layout>
      <main className="flex-1 w-full max-w-4xl px-4 py-8 mx-auto md:px-8 md:py-16">
        <SEO
          keywords={[
            `Tech companies in germany`,
            `Work in germany`,
            `information technology germany`,
          ]}
          title="About"
        />

        <section className="flex flex-col items-center md:flex-row">
          <div className="md:w-2/3 md:mr-8">
            <h3 className="font-normal">
              {" "}
              Whether you are looking for a job in Germany, or just wondering if
              Google or Amazon offices are in Germany, you can use this website
              to explore and find the locations of all tech companies around
              Germany and get informed about them.
            </h3>
            <h2>It&apos;s just like google maps for companies in Germany 🇩🇪</h2>
          </div>

          <figure className="w-2/3 md:w-1/3">
            <img alt="A dog relaxing" src={dogIllustration} />
          </figure>
        </section>
      </main>
    </Layout>
  );
}

export default AboutPage;
